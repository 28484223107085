/* eslint-disable prefer-destructuring */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import { Button } from '@material-ui/core'
import * as React from 'react'
import { graphql } from 'gatsby'
import { Parallax, Background } from 'react-parallax'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Carousel from '../../components/carousel'
import Maps from '../../components/maps'
import Container from '../../components/container'
import Layout from '../../components/layout/index'
import Translate from '../../components/translation/translate'
import './styles.scss'
import 'animate.css'
import bg1 from '../../../static/Home_picture_1.jpg'
import clock from '../../../static/clock-icon.svg'
import bgPiatto from '../../../static/Piatto.jpg'
import location from '../../../static/location-icon.svg'
import bg2 from '../../../static/Home_picture_2.jpg'

const HomePage = ({ data }) => {
    const menuRef = React.useRef(null)

    const Bold = ({ children }) => <span className="bold">{children}</span>
    const Text = ({ children }) => <p className="align-center">{children}</p>

    const options = {
        renderMark: {
            [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
        },
    }

    let path = ''
    if (typeof window !== 'undefined') {
        path = window.location.pathname.split('/')[1]
    }
    const language = path !== '' ? `${path}` : 'nl'

    const { contentfulTimetable, allContentfulBachecaAvvisi, contentfulHomePage } = data

    const getBacheca = () => {
        let bacheca = ''
        if (allContentfulBachecaAvvisi.edges.find((o) => o.node.node_locale.includes(language))) {
            bacheca = allContentfulBachecaAvvisi.edges.find((o) =>
                o.node.node_locale.includes(language),
            ).node.avvisi.raw
        } else bacheca = allContentfulBachecaAvvisi.edges[0].node.avvisi.raw
        if (documentToReactComponents(JSON.parse(bacheca), options)[0].props.children[0].length > 1)
            return documentToReactComponents(JSON.parse(bacheca), options)
        return undefined
    }

    return (
        <Layout className="homepage">
            <div className="intro-section">
                <Parallax bgImage={bgPiatto} strength={500}>
                    <div className="main-image" />
                </Parallax>
                <div className="buttons-wrapper">
                    <div>
                        <Button
                            variant="outlined"
                            className="intro-button"
                            onClick={() =>
                                window.scrollTo({
                                    top: menuRef.current.node.offsetTop,
                                    left: 0,
                                    behavior: 'smooth',
                                })
                            }
                        >
                            <h2>
                                <Translate>HOMEPAGE.MENU</Translate>
                            </h2>
                        </Button>
                    </div>
                    <div>
                        <a href={Translate({ children: 'SOCIALS.LIFERANDO' })}>
                            <Button variant="outlined" className="intro-button">
                                <h2>
                                    <Translate>HOMEPAGE.ORDER</Translate>
                                </h2>
                            </Button>
                        </a>
                    </div>
                </div>
            </div>
            {getBacheca() ? (
                <div className="noticeboard-section">
                    <Container>
                        <div className="noticeboard">
                            <h2 className="animate__animated animate__flash animate__slow animate__infinite">
                                <Translate>HOMEPAGE.NEWS</Translate>
                            </h2>
                            {getBacheca()}
                        </div>
                    </Container>
                </div>
            ) : null}
            <div className="first-section">
                <Container>
                    <div className="first-description">
                        <h2>
                            <Translate>HOMEPAGE.DESCRIPTION2</Translate>
                        </h2>
                    </div>
                    <div className="row">
                        <div className="second-description">
                            <h4>
                                <Translate>HOMEPAGE.DESCRIPTION1</Translate>
                            </h4>
                            <h4>
                                <Translate>HOMEPAGE.DESCRIPTION3</Translate>
                            </h4>
                        </div>
                        <Parallax bgImage={bg2} strength={280} className="first-section-px">
                            <div className="second-image" />
                        </Parallax>
                    </div>
                </Container>
            </div>
            <Parallax bgImage={bg2} strength={280} className="first-section-px-mobile">
                <div className="second-image" />
            </Parallax>
            <div className="second-section">
                <Container>
                    <div>
                        <h2>
                            <Translate>HOMEPAGE.CLAIM</Translate>
                        </h2>
                    </div>
                </Container>
            </div>
            <Parallax strength={280} ref={menuRef}>
                <Background>
                    <img src={bg1} alt="fill murray" />
                </Background>
                <div className="menu-image">
                    <h1>
                        <Translate>HOMEPAGE.MENU</Translate>
                    </h1>
                </div>
            </Parallax>
            <div className="third-section">
                <Container className="third-sec-container">
                    <div>
                        <div className="list-description">
                            <h2 className="list-title">
                                <Translate>HOMEPAGE.FEATURES_LIST_TITLE</Translate>
                            </h2>
                            <h4 className="list-item">
                                -<Translate>HOMEPAGE.FEATURE1</Translate>
                            </h4>
                            <h4 className="list-item">
                                -<Translate>HOMEPAGE.FEATURE2</Translate>
                            </h4>
                            <h4 className="list-item">
                                -<Translate>HOMEPAGE.FEATURE3</Translate>
                            </h4>

                            <h4>
                                <Translate>HOMEPAGE.INFO2</Translate>
                            </h4>
                            <h4>
                                <Translate>HOMEPAGE.INFO3</Translate>
                            </h4>
                        </div>
                        <div className="cta">
                            <a
                                href="https://docs.google.com/document/d/e/2PACX-1vQUsutdoGnf9ED3TjZTsXITGnFsjQ3zOxqTYkbqHwslnPyCw_N5-3C_9LXhhSipCWHCo3huA4vA1nco/pub"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <Button variant="outlined" className="button-dark">
                                    <h2>
                                        <Translate>HOMEPAGE.MENU</Translate>
                                    </h2>
                                </Button>
                            </a>
                            <a
                                href="https://docs.google.com/document/d/e/2PACX-1vR5G_hxxoJyRI_KDzNhA2gt6QGKwmtZDwRVd7dl_IUSAH-wAETmxaVey7JUkG0rNbgi-rA4Nvt2etYs/pub"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <Button variant="outlined" className="button-dark">
                                    <h2>
                                        <Translate>HOMEPAGE.VEGAN_MENU</Translate>
                                    </h2>
                                </Button>
                            </a>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="info-section">
                <Container>
                    <h1>
                        <Translate>HOMEPAGE.INFO</Translate>
                    </h1>
                    <div className="time-wrapper">
                        <img src={clock} alt="" />
                        <h3>
                            <Translate>HOMEPAGE.TIME</Translate>
                        </h3>
                    </div>
                    <div className="divider" />
                    {Object.keys(contentfulTimetable).map((item) => (
                        <div className="timetable-row" key={item}>
                            <h3>
                                <Translate>{`TIME.${item.toUpperCase()}`}</Translate>
                            </h3>
                            <h3>{contentfulTimetable[item]}</h3>
                        </div>
                    ))}
                    <div className="divider" />
                    <div className="position-contact-wrapper">
                        <div className="position-desc">
                            <div>
                                <div>
                                    <img src={location} alt="" />
                                    <h3>
                                        <Translate>HOMEPAGE.LOCATION</Translate>
                                    </h3>
                                </div>
                                <h4>
                                    <Translate>HOMEPAGE.INFO1</Translate>
                                </h4>
                            </div>
                        </div>
                        <div style={{ height: '400px' }}>
                            <Maps />
                        </div>
                    </div>
                </Container>
            </div>
            <Carousel images={contentfulHomePage.galleriaImmagini} />
        </Layout>
    )
}

export const query = graphql`
    query TimetableQuery {
        contentfulTimetable {
            monday
            tuesday
            wednesday
            thursday
            friday
            saturday
        }
        allContentfulBachecaAvvisi {
            edges {
                node {
                    node_locale
                    avvisi {
                        raw
                    }
                }
            }
        }
        contentfulHomePage {
            titolo
            galleriaImmagini {
                fluid(maxWidth: 500, quality: 85) {
                    ...GatsbyContentfulFluid
                }
            }
        }
    }
`

export default HomePage
