/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import * as React from 'react'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'

export class MapContainer extends React.Component {
    render() {
        const style = {
            width: '100%',
            height: 'auto',
        }
        return (
            <Map
                google={this.props.google}
                zoom={18}
                style={style}
                initialCenter={{
                    lat: 50.7355,
                    lng: 7.1018,
                }}
            >
                <Marker
                    onClick={this.onMarkerClick}
                    name="Current location"
                    position={{ lat: 50.7355, lng: 7.1018 }}
                />
            </Map>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyCenIr32Tl1_4N0I69ufjdNRP6V63kd6Xc',
})(MapContainer)
