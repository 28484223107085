/* eslint-disable react/prop-types */
import React from 'react'
import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import Img from 'gatsby-image'
import './styles.scss'

const Carousel = ({ images }) => {
    const [pause, setPause] = React.useState(false)
    const timer = React.useRef()
    const [sliderRef, slider] = useKeenSlider({
        initial: 0,
        slidesPerView: 3,
        loop: true,
    })

    React.useEffect(() => {
        timer.current = setInterval(() => {
            if (!pause && slider) {
                slider.next()
            }
        }, 3500)
        return () => {
            clearInterval(timer.current)
        }
    }, [pause, slider])

    return (
        <div
            className="carousel-wrapper"
            onMouseEnter={() => setPause(true)}
            onMouseLeave={() => setPause(false)}
        >
            <div ref={sliderRef} className="keen-slider">
                {images
                    ? images.map((item, index) => (
                          <div className={`keen-slider__slide number-slide${index}`}>
                              <div>
                                <Img fluid={item.fluid} />
                              </div>
                          </div>
                      ))
                    : null}
            </div>
            {slider && (
                <>
                    <ArrowLeft onClick={(e) => e.stopPropagation() || slider.prev()} />
                    <ArrowRight onClick={(e) => e.stopPropagation() || slider.next()} />
                </>
            )}
        </div>
    )
}

export default Carousel

const ArrowLeft = (props) => {
    const { disabled, onClick } = props
    return (
        <svg
            onClick={onClick}
            className={`arrow arrow--left${disabled ? ' arrow--disabled' : ''}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
        </svg>
    )
}

const ArrowRight = (props) => {
    const { disabled, onClick } = props
    return (
        <svg
            onClick={onClick}
            className={`arrow arrow--right${disabled ? ' arrow--disabled' : ''}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
        </svg>
    )
}
